import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { useLanguage } from './LanguageContext';
import LoadingPage from './LoadingPage';
import NotFoundPage from './NotFoundPage';
import AuthorisationError from './AuthorisationError';
import Cookies from 'js-cookie';
import './GeneratePage.css';
import PaymentSuccessPage from './PaymentSuccessPage';
import PaymentFailurePage from './PaymentFailurePage';
import 'react-toastify/dist/ReactToastify.css';

const CandidateCard = ({ candidate }) => {
  const { language } = useLanguage();
  const strings = require(`./${language}.json`);

  useEffect(() => {
    document.documentElement.lang = language;
  }, [language]);

  const formattedDate = new Date(candidate.date_of_passage).toLocaleString('ru-RU', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit'
  });

  const handleCardClick = () => {
    window.location.href = `${window.location.origin}/report-result/${candidate.user_id}`;
  };

  return (
    <div className="card" onClick={handleCardClick}>
      <div className="card-header">
        <h2>{candidate.first_name} {candidate.last_name}</h2>
      </div>
      <div className="card-body">
        <p>{strings.dateOfPassage}{formattedDate}</p>
      </div>
    </div>
  );
};

const GeneratePage = () => {
  const token = Cookies.get('authToken');
  const [error, setError] = useState(null);
  const [welcomeError, setWelcomeError] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [lastGeneratedLink, setLastGeneratedLink] = useState(null);
  const [generatedLink, setGeneratedLink] = useState(null);
  const [clientName, setClientName] = useState(null);
  const [candidates, setCandidates] = useState([]);
  const [loading, setLoading] = useState(true);
  const [notFound, setNotFound] = useState(false);
  const [testPassed, setTestPassed] = useState(false);
  const [authorisationError, setAuthorisationError] = useState(false);
  const [paymentStatus, setPaymentStatus] = useState(null); // Состояние для отслеживания статуса оплаты
  const [subscriptionIsActive, setSubscriptionIsActive] = useState(null);
  const [subscriptionDate, setSubscriptionDate] = useState(null);
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('ru-RU', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit'
    });
  };

  const { language } = useLanguage();
  const strings = require(`./${language}.json`);

  useEffect(() => {
    document.documentElement.lang = language;
  }, [language]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          window.location.origin === 'http://localhost:3000'
            ? 'http://localhost:8000/api/generate/'
            : `${window.location.origin}/api/generate/`,
          {
            headers: {
              Authorization: `Token ${token}`
            }
          }
        );
        setLastGeneratedLink(`${window.location.origin}/${response.data.last_generated_link}`);
        setGeneratedLink(`${response.data.last_generated_link}`);
        setClientName(response.data.client_name);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        handleRequestError(error);
      }
    };

    fetchData();
  }, [token]);

  useEffect(() => {
    const fetchUserCreatorData = async () => {
      const token = Cookies.get('authToken'); // Получаем токен из cookies
      try {
        const response = await axios.get(
          window.location.origin === 'http://localhost:3000'
            ? 'http://localhost:8000/api/user_creator_data/'
            : `${window.location.origin}/api/user_creator_data/`,
          {
            headers: {
              Authorization: `Token ${token}`,
              'Content-Type': 'application/json',
            },
          }
        );

        setTestPassed(response.data.test_passed); // Сохраняем user_creator_id в состоянии
        setNotFound(false);
      } catch (error) {
        console.error('Error fetching user creator data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchUserCreatorData();
  }, []); // Пустой массив зависимостей, чтобы функция вызывалась только при монтировании компонента

  useEffect(() => {
    const fetchCandidates = async () => {
      try {
        const response = await axios.get(
          window.location.origin === 'http://localhost:3000'
            ? 'http://localhost:8000/api/candidates_list/'
            : `${window.location.origin}/api/candidates_list/`,
          {
            headers: {
              Authorization: `Token ${token}`
            }
          }
        );
        setCandidates(response.data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        handleRequestError(error);
      }
    };

    fetchCandidates();
  }, [token]);

  useEffect(() => {
    const fetchSubscriptionStatus = async () => {
      try {
        const response = await axios.get(
          window.location.origin === 'http://localhost:3000'
            ? 'http://localhost:8000/api/subscription_status/'
            : `${window.location.origin}/api/subscription_status/`,
          {
            headers: {
              Authorization: `Token ${token}`
            }
          }
        );
        setSubscriptionIsActive(response.data.subscription_is_active);
        setSubscriptionDate(formatDate(response.data.subscription_end_time));

      } catch (error) {
        handleRequestError(error);
      }
    };

    fetchSubscriptionStatus();
  }, [token]);

  const handleSubscription = async () => {
    try {
      const response = await axios.post(
        window.location.origin === 'http://localhost:3000'
          ? 'http://localhost:8000/api/create_subscription/'
          : `${window.location.origin}/api/create_subscription/`,
        {},
        {
          headers: {
            Authorization: `Token ${token}`
          }
        }
      );
      window.location.href = response.data.subscription_url;
    } catch (error) {
      handleRequestError(error);
    }
  };

  const handlePaymentSuccess = useCallback(async () => {
    try {
      const params = new URLSearchParams(window.location.search);
      const session_id = params.get('session_id');

      if (!session_id) {
        throw new Error('Session ID is missing in the URL parameters.');
      }

      const response = await axios.get(
        window.location.origin === 'http://localhost:3000'
          ? `http://localhost:8000/api/handle-payment-success/?session_id=${session_id}`
          : `${window.location.origin}/api/handle-payment-success/?session_id=${session_id}`,
        {
          headers: {
            Authorization: `Token ${token}`
          }
        }
      );

      console.log(response.data.message);

      const newUrl = new URL(window.location);
      newUrl.searchParams.delete('success');
      newUrl.searchParams.delete('session_id');
      window.history.replaceState(null, '', newUrl);

    } catch (error) {
      console.error('Error handling payment success:', error);
      // Обработка ошибок при обработке успешной оплаты
    }
  }, [token]);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    if (params.get('success')) {
      setPaymentStatus('success');
    } else if (params.get('canceled')) {
      setPaymentStatus('canceled');
    }
  }, []);

  useEffect(() => {
    if (paymentStatus === 'success') {
      handlePaymentSuccess();
    }
  }, [paymentStatus, handlePaymentSuccess, token]);

  const handleRequestError = (error) => {
    setLoading(false);
    if (error.response) {
      if (error.response.status === 401 || error.response.status === 403) {
        setAuthorisationError(true);
        Cookies.remove('authToken');
      } else if (error.response.status === 404) {
        setNotFound(true);
        setError('Not Found');
      } else {
        // setError('An unexpected error occurred. Please try again later.');
        console.error('API error:', error.response.data.detail);
      }
    } else {
      setError('An unexpected error occurred. Please try again later.');
      console.error('Error:', error.message);
    }
  };

  const handleTestButtonClick = async () => {
    try {
      const link = generatedLink;
      Cookies.set('lastTestLink', link);
      const response = await axios.post(
        `${window.location.origin === 'http://localhost:3000' ? 'http://localhost:8000' : window.location.origin}/api/user/${link}/welcome/`,
        {},
        {
          headers: {
            Authorization: `Token ${token}`
          }
        }
      );

      const userId = response.data.user_id;
      if (userId) {
        window.open(`/user/${link}/question/${userId}`, '_blank');
      }
    } catch (error) {
      if (error.response && error.response.data && error.response.data.detail) {
        // Если ошибка содержит поле detail, используем его
        setWelcomeError(error.response.data.detail);
      } else {
        // Если структура ошибки другая, используем общее сообщение
        setWelcomeError('An unexpected error occurred. Please try again later.');
      }
      console.error('Error fetching user ID:', error);
    }
  };

  if (loading) {
    return <LoadingPage />;
  }

  if (notFound) {
    return <NotFoundPage />;
  }

  if (authorisationError) {
    Cookies.remove('authToken');
    return <AuthorisationError />;
  }

  if (paymentStatus === 'success') {
    return <PaymentSuccessPage />;
  } else if (paymentStatus === 'failure') {
    return <PaymentFailurePage />;
  }

  return (
    <div>
      <h1>{strings.welcome} {clientName}</h1>
      {!error || (error && error !== "Maximum email count reached.") ? (
        <>
          {token ? (
            <div>
              <div>
                <h3 className='yourLink'>{strings.yourLink}</h3>
                {!testPassed && (
                  <>
                    <div className="container">
                      <h3>
                        {strings.toKnowYou}
                      </h3>
                      <button onClick={handleTestButtonClick}>{strings.takeTest}</button>
                    {welcomeError && <p style={{ color: "red" }}>{welcomeError}</p>}
                  </div>
                    </>
                  )}
                <div className="container">
                  {subscriptionIsActive && subscriptionDate && (
                    <p>{`${strings.subscriptionIsActive}`}</p>
                  )}
                  {subscriptionIsActive === false && subscriptionDate !== null && (
                    <div>
                      <p>{`${strings.subscriptionIsNotActive}`}</p>
                      <button style={{ marginTop: "30px" }} onClick={handleSubscription}>{strings.subscribe}</button>
                    </div>
                  )}
                </div>
              </div>
              <div className="generate-page">
                {error && <p>{error}</p>}
                {candidates.length > 0 ? (
                  <div className="reports-section">
                    <h3 className="reports-header">{strings.Reports}</h3>
                    {candidates.map(candidate => (
                      <CandidateCard key={candidate.user_id} candidate={candidate} />
                    ))}
                  </div>
                ) : (
                  <h3 className="no-reports">{strings.NoReports}</h3>
                )}
              </div>
            </div>
          ) : (
            <p>{strings.logIn}</p>
          )}
        </>
      ) : null}
    </div>
  );
};

export default GeneratePage;