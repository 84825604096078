import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import axios from 'axios';
import { useLanguage } from './LanguageContext';
import LoadingPage from './LoadingPage';
import AuthorisationError from './AuthorisationError';
import NotFoundPage from './NotFoundPage';
import SomethingWrong from './SomethingWrong';
import './Report.css';
import Cookies from 'js-cookie';

function Report() {
    const { language } = useLanguage();
    const strings = require(`./${language}.json`);
    useEffect(() => {
        document.documentElement.lang = language;
    }, [language]);

    const { userId } = useParams();
    const history = useHistory();

    const [userData, setUserData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [notFound, setNotFound] = useState(false);
    const [isSomethingWrong, setSomethingWrong] = useState(false);
    const [authorisationError, setAuthorisationError] = useState(false);
    // const [errorText, setErrorText] = useState(null);
    // eslint-disable-next-line no-unused-vars
    const [paramImages, setParamImages] = useState([]);
    // eslint-disable-next-line no-unused-vars
    const [competencyMapParameter, setCompetencyMapParameter] = useState([]);



    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const token = Cookies.get('authToken');
                const response = await axios.get(
                    `${window.location.origin === 'http://localhost:3000' ? 'http://localhost:8000' : window.location.origin}/api/user/${userId}/report-result/`,
                    {
                        headers: {
                            Authorization: `Token ${token}`
                        }
                    }
                );
                setUserData(response.data);
                setLoading(false);

                const { manager_params } = response.data;
                if (manager_params && manager_params.length > 0) {
                    let images = [];
                    let competency = [];

                    manager_params.forEach(param => {
                        if (param.param_image && param.param_image.length > 0) {
                            images = [...images, ...param.param_image];
                        }
                        if (param.competencyMapParameter && param.competencyMapParameter.length > 0) {
                            competency = [...competency, ...param.competencyMapParameter];
                        }
                    });
                    setParamImages(images);
                    setCompetencyMapParameter(competency);
                }
            } catch (error) {
                console.error('Error fetching user data:', error);
                setLoading(false);
                if (error.response) {
                    if (error.response.status === 401) {
                        setAuthorisationError(true);
                    } else if (error.response.status === 403) {
                        setAuthorisationError(true);
                    } else if (error.response.status === 404) {
                        setNotFound(true);
                    } else {
                        setSomethingWrong(true);
                    }
                } else {
                    setSomethingWrong(true);
                }
            }
        };

        fetchUserData();
    }, [userId, history]);

    const [expandedParams, setExpandedParams] = useState({});

    const toggleParam = (paramName) => {
        setExpandedParams(prevState => ({
            ...prevState,
            [paramName]: !prevState[paramName]
        }));
    };

    const handleBackClick = () => {
        history.push('/account');
    };

    const handleRedirect = () => {
        history.push('/');
    };

    if (loading) {
        return <LoadingPage />;
    }

    if (authorisationError) {
        return <AuthorisationError />;
    }
    if (notFound) {
        return (
            <div>
                <NotFoundPage />
                <button onClick={handleRedirect}>{strings.ReturnToHome}</button>
            </div>
        );
    }

    if (isSomethingWrong) {
        return <SomethingWrong />;
    }

    if (!userData) {
        return <SomethingWrong />;
    }

    return (
        <div className="report-container">
            <div className="user-info">
                <div className="back-button" style={{ marginTop: '-100px' }} onClick={handleBackClick}>
                    &larr; {strings.backAccount}
                </div>
                <img src="https://static.wixstatic.com/media/a678e3_fa726771ffd44ce2bef6352a2e32292d~mv2.png/v1/crop/x_24,y_17,w_279,h_291/fill/w_115,h_118,fp_0.50_0.50,q_85,usm_0.66_1.00_0.01,enc_auto/person.png" alt="Human Icon" />
                <h2>{`${userData.first_name} ${userData.last_name}`}</h2>
            </div>
            <div className="contact-info">
                <img src="https://static.wixstatic.com/media/a678e3_871e2916c3354e69a3a44d105694eb97~mv2.png/v1/crop/x_0,y_0,w_114,h_103/fill/w_55,h_53,fp_0.50_0.50,q_85,usm_0.66_1.00_0.01,enc_auto/_.png" alt="Contact Icon" />
                <div>
                    <p><strong>Email:</strong> {userData.email}</p>
                    {userData.linkedin && <p><strong>LinkedIn:</strong> <a href={userData.linkedin} target="_blank" rel="noopener noreferrer">{userData.linkedin}</a></p>}
                </div>
            </div>
            <div className="user-info">
            </div>
            <h2>{strings.managerParams}</h2>

            {userData.manager_params.slice(0, 1).map((param) => (
                <div className="params-row">
                    <div key={param.param_name} className="param-box param-box-extra-margin special-box2">
                        {/* Функция сворачивания блоков */}
                        {/* <button className="toggle-button" onClick={toggleAdditionalText}>
                        {additionalTextExpanded ? '-' : '+'}
                    </button> */}
                        <div className="param-icon">
                            <img src="https://static.wixstatic.com/media/a678e3_0e50ab884f79496db17288d830ace61b~mv2.png/v1/crop/x_1,y_0,w_61,h_64/fill/w_24,h_25,fp_0.50_0.50,q_85,usm_0.66_1.00_0.01,enc_auto/values.png" alt="Icon" />
                        </div>
                        <h3
                            className={`param-name ${expandedParams[param.param_name] ? 'expanded' : ''}`}
                            onClick={() => toggleParam(param.param_name)}
                        >
                            {param.param_name}
                        </h3>
                        <div className={`param-description ${expandedParams[param.param_name] ? 'param-description-expanded' : ''}`}>
                            {Array.isArray(param.description) && param.description.length > 0 &&
                                param.description[0].split('\n').map((line, index) => (
                                    <p key={index}>{line}</p>
                                ))
                            }
                        </div>
                        {param.param_text && param.param_text.split('\n').map((line, index) => (
                            <p className="text_param" key={index} dangerouslySetInnerHTML={{ __html: line }} />
                        ))}
                    </div>
                </div>
            ))}

            <div className="params-container">
                {userData.manager_params.slice(1, 7).map((param, index) => (
                    <div key={param.param_name} className="param-box">
                        <div className="param-icon">
                            {index === 0 && <img src="https://static.wixstatic.com/media/a678e3_4400e47f58b14902b46519835c64d03d~mv2.png/v1/fill/w_40,h_27,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/role.png" alt="Icon" />}
                            {index === 1 && <img src="https://static.wixstatic.com/media/a678e3_750f807265b544bc8ba66fde6bb453c8~mv2.png/v1/crop/x_0,y_15,w_114,h_76/fill/w_42,h_28,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/meta.png" alt="Icon" />}
                            {index === 2 && <img src="https://static.wixstatic.com/media/a678e3_96ed2d1a3bc94601a2818fefd7908f5d~mv2.png/v1/fill/w_52,h_35,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/motiv.png" alt="Icon" />}
                            {index === 3 && <img src="https://static.wixstatic.com/media/a678e3_c19c07278e6f463580494e92f7673cd3~mv2.png/v1/fill/w_46,h_31,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/target.png" alt="Icon" />}
                            {index === 4 && <img src="https://static.wixstatic.com/media/a678e3_732e4a69d86549a5a0d597a424b31266~mv2.png/v1/fill/w_55,h_37,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/group.png" alt="Icon" />}
                            {index === 5 && <img src="https://static.wixstatic.com/media/a678e3_45d21acd225646b986e4ebf76da0fb54~mv2.png/v1/fill/w_46,h_31,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/growa.png" alt="Icon" />}
                        </div>
                        <h3
                            className={`param-name ${expandedParams[param.param_name] ? 'expanded' : ''}`}
                            onClick={() => toggleParam(param.param_name)}
                        >
                            {param.param_name}
                        </h3>
                        <div className={`param-description ${expandedParams[param.param_name] ? 'param-description-expanded' : ''}`}>
                            {Array.isArray(param.description) && param.description.length > 0 &&
                                param.description[0].split('\n').map((line, index) => (
                                    <p key={index}>{line}</p>
                                ))
                            }
                        </div>
                        <div className="param-image-container">
                            {/* Если это 5-й элемент, отобразите тексты над изображением */}
                            {index === 3 && (
                                <>
                                    {param.image_link && <div className="image-text-left">{strings.processReport}</div>}
                                    {param.image_link && <div className="image-text-right">{strings.resultReport}</div>}
                                </>
                            )}
                            {/* Если это 6-й элемент, отобразите тексты над изображением */}
                            {index === 4 && (
                                <>
                                    {param.image_link && <div className="image-text-left">{strings.onMyselfReport}</div>}
                                    {param.image_link && <div className="image-text-right">{strings.onTheTeamReport}</div>}
                                </>
                            )}
                            {/* Отобразите изображение */}
                            {param.image_link && <img className="param-image" src={param.image_link} alt="Img" />}
                        </div>
                        {param.param_text && param.param_text.split('\n').map((line, index) => (
                            <p className="text_param" key={index} dangerouslySetInnerHTML={{ __html: line }} />
                        ))}
                    </div>
                ))}
            </div>
            <div className="params-row">
                {userData.manager_params.slice(7, 8).map((param, index) => ( // Что бы добавить 9 параметр -> Заменить на slice(7, 9)
                    <div key={param.param_name} className="param-box param-box-extra-margin">
                        <div className="param-icon">
                            {index === 0 && <img src="https://static.wixstatic.com/media/a678e3_a5743a1105434a09ba7a9e54978d350f~mv2.png/v1/fill/w_51,h_34,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/sun.png" alt="Icon" />}
                            {index === 1 && <img src="https://static.wixstatic.com/media/a678e3_c9358841b0f0402084e25af779446f6f~mv2.png/v1/fill/w_49,h_33,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/manage.png" alt="Icon" />}
                        </div>
                        <h3
                            className={`param-name ${expandedParams[param.param_name] ? 'expanded' : ''}`}
                            onClick={() => toggleParam(param.param_name)}
                        >
                            {param.param_name}
                        </h3>
                        <div className={`param-description ${expandedParams[param.param_name] ? 'param-description-expanded' : ''}`}>
                            {Array.isArray(param.description) && param.description.length > 0 &&
                                param.description[0].split('\n').map((line, index) => (
                                    <p key={index}>{line}</p>
                                ))
                            }
                        </div>
                        <div className="param-image-container">
                            {/* Отобразите изображение */}
                            {param.image_link && <img className="param-image" src={param.image_link} alt="Img" />}
                        </div>
                        {param.param_text && param.param_text.split('\n').map((line, index) => (
                            <p className="text_param" key={index} dangerouslySetInnerHTML={{ __html: line }} />
                        ))}
                    </div>
                ))}
            </div>
            {/* Карта компетенций */}
            {/* {userData.manager_params.slice(9, 10).map((param) => (
                <div className="params-row">
                    <div key={param.param_name} className="param-box param-box-extra-margin special-box">
                        <div className="param-icon">
                            <img src="https://static.wixstatic.com/media/a678e3_accdbab8618e41d9b36307da5dda3ecc~mv2.png/v1/fill/w_48,h_32,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/competency.png" alt="Icon" />
                        </div>
                        <h3
                            className={`param-name ${expandedParams[param.param_name] ? 'expanded' : ''}`}
                            onClick={() => toggleParam(param.param_name)}
                        >
                            {param.param_name}
                        </h3>
                        <div className={`param-description ${expandedParams[param.param_name] ? 'param-description-expanded' : ''}`}>
                            {Array.isArray(param.description) && param.description.length > 0 &&
                                param.description[0].split('\n').map((line, index) => (
                                    <p key={index}>{line}</p>
                                ))
                            }
                        </div>
                        <div className="additional-text">
                            <div className="competency-item">
                                <div className="text">
                                    <p dangerouslySetInnerHTML={{ __html: competencyMapParameter[0] }}></p>
                                </div>
                                <div className="image">
                                    <img src={paramImages[0]} alt="Competency" />
                                </div>
                            </div>
                            <div className="competency-item">
                                <div className="text">
                                    <p dangerouslySetInnerHTML={{ __html: competencyMapParameter[1] }}></p>
                                </div>
                                <div className="image">
                                    <img src={paramImages[1]} alt="Competency" />
                                </div>
                            </div>
                            <div className="competency-item">
                                <div className="text">
                                    <p dangerouslySetInnerHTML={{ __html: competencyMapParameter[2] }}></p>
                                </div>
                                <div className="image">
                                    <img src={paramImages[2]} alt="Competency" />
                                </div>
                            </div>
                            <div className="competency-item">
                                <div className="text">
                                    <p dangerouslySetInnerHTML={{ __html: competencyMapParameter[3] }}></p>
                                </div>
                                <div className="image">
                                    <img src={paramImages[3]} alt="Competency" />
                                </div>
                            </div>
                            <div className="competency-item">
                                <div className="text">
                                    <p dangerouslySetInnerHTML={{ __html: competencyMapParameter[4] }}></p>
                                </div>
                                <div className="image">
                                    <img src={paramImages[4]} alt="Competency" />
                                </div>
                            </div>
                            <div className="competency-item">
                                <div className="text">
                                    <p dangerouslySetInnerHTML={{ __html: competencyMapParameter[5] }}></p>
                                </div>
                                <div className="image">
                                    <img src={paramImages[5]} alt="Competency" />
                                </div>
                            </div>
                            <div className="competency-item">
                                <div className="text">
                                    <p dangerouslySetInnerHTML={{ __html: competencyMapParameter[6] }}></p>
                                </div>
                                <div className="image">
                                    <img src={paramImages[6]} alt="Competency" />
                                </div>
                            </div>
                            <div className="competency-item">
                                <div className="text">
                                    <p dangerouslySetInnerHTML={{ __html: competencyMapParameter[7] }}></p>
                                </div>
                                <div className="image">
                                    <img src={paramImages[7]} alt="Competency" />
                                </div>
                            </div>
                            <div className="competency-item">
                                <div className="text">
                                    <p dangerouslySetInnerHTML={{ __html: competencyMapParameter[8] }}></p>
                                </div>
                                <div className="image">
                                    <img src={paramImages[8]} alt="Competency" />
                                </div>
                            </div>
                            <div className="competency-item">
                                <div className="text">
                                    <p dangerouslySetInnerHTML={{ __html: competencyMapParameter[9] }}></p>
                                </div>
                                <div className="image">
                                    <img src={paramImages[9]} alt="Competency" />
                                </div>
                            </div>
                            <div className="competency-item">
                                <div className="text">
                                    <p dangerouslySetInnerHTML={{ __html: competencyMapParameter[10] }}></p>
                                </div>
                                <div className="image">
                                    <img src={paramImages[10]} alt="Competency" />
                                </div>
                            </div>
                            <div className="competency-item">
                                <div className="text">
                                    <p dangerouslySetInnerHTML={{ __html: competencyMapParameter[11] }}></p>
                                </div>
                                <div className="image">
                                    <img src={paramImages[11]} alt="Competency" />
                                </div>
                            </div>
                            <div className="competency-item">
                                <div className="text">
                                    <p dangerouslySetInnerHTML={{ __html: competencyMapParameter[12] }}></p>
                                </div>
                                <div className="image">
                                    <img src={paramImages[12]} alt="Competency" />
                                </div>
                            </div>
                            <div className="competency-item">
                                <div className="text">
                                    <p dangerouslySetInnerHTML={{ __html: competencyMapParameter[13] }}></p>
                                </div>
                                <div className="image">
                                    <img src={paramImages[13]} alt="Competency" />
                                </div>
                            </div>
                            <div className="competency-item">
                                <div className="text">
                                    <p dangerouslySetInnerHTML={{ __html: competencyMapParameter[14] }}></p>
                                </div>
                                <div className="image">
                                    <img src={paramImages[14]} alt="Competency" />
                                </div>
                            </div>
                            <div className="competency-item">
                                <div className="text">
                                    <p dangerouslySetInnerHTML={{ __html: competencyMapParameter[15] }}></p>
                                </div>
                                <div className="image">
                                    <img src={paramImages[15]} alt="Competency" />
                                </div>
                            </div>
                            <div className="competency-item">
                                <div className="text">
                                    <p dangerouslySetInnerHTML={{ __html: competencyMapParameter[16] }}></p>
                                </div>
                                <div className="image">
                                    <img src={paramImages[16]} alt="Competency" />
                                </div>
                            </div>
                            <div className="competency-item">
                                <div className="text">
                                    <p dangerouslySetInnerHTML={{ __html: competencyMapParameter[17] }}></p>
                                </div>
                                <div className="image">
                                    <img src={paramImages[17]} alt="Competency" />
                                </div>
                            </div>
                            <div class="competency-item">
                                <div class="text">
                                    <p dangerouslySetInnerHTML={{ __html: competencyMapParameter[18] }}></p>
                                </div>
                                <div class="image">
                                    <img src={paramImages[18]} alt="Competency" />
                                </div>
                            </div>
                            <div class="competency-item">
                                <div class="text">
                                    <p dangerouslySetInnerHTML={{ __html: competencyMapParameter[19] }}></p>
                                </div>
                                <div class="image">
                                    <img src={paramImages[19]} alt="Competency" />
                                </div>
                            </div>
                            <div class="competency-item">
                                <div class="text">
                                    <p dangerouslySetInnerHTML={{ __html: competencyMapParameter[20] }}></p>
                                </div>
                                <div class="image">
                                    <img src={paramImages[20]} alt="Competency" />
                                </div>
                            </div>
                            <div class="competency-item">
                                <div class="text">
                                    <p dangerouslySetInnerHTML={{ __html: competencyMapParameter[21] }}></p>
                                </div>
                                <div class="image">
                                    <img src={paramImages[21]} alt="Competency" />
                                </div>
                            </div>
                            <div class="competency-item">
                                <div class="text">
                                    <p dangerouslySetInnerHTML={{ __html: competencyMapParameter[22] }}></p>
                                </div>
                                <div class="image">
                                    <img src={paramImages[22]} alt="Competency" />
                                </div>
                            </div>
                            <div class="competency-item">
                                <div class="text">
                                    <p dangerouslySetInnerHTML={{ __html: competencyMapParameter[23] }}></p>
                                </div>
                                <div class="image">
                                    <img src={paramImages[23]} alt="Competency" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ))} */}
            <div className="back-button" onClick={handleBackClick}>
                &larr; {strings.backAccount}
            </div>
        </div>
    );
}

export default Report;