import React from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import Header from './header_footer/Header';
import Footer from './header_footer/Footer';
import LoginPage from './LoginPage';
import RegistrationPage from './RegistrationPage';
import GeneratePage from './GeneratePage';
import Settings from './Settings';
import Questions1 from './Questions1';
import SecondQuestions from './SecondQuestions';
import Result from './Result';
import Report from './Report';
import Coach from './Coach'
import './styles.css';
import { LanguageProvider } from './LanguageContext';
import ProtectedRoute from './ProtectedRoute';

function App() {
  return (
    <LanguageProvider>
      <Router>
        <div className="app-container">
          <Header />
          <div className="content">
            <Switch>
              <Route exact path="/">
                <LoginPage />
              </Route>
              <Route exact path="/sign_up">
                <RegistrationPage />
              </Route>
              <Route exact path="/account">
                <GeneratePage />
              </Route>
              <Route exact path="/settings">
                <Settings />
              </Route>
              <Route path="/user/:userId/question/:data_userId">
                <Questions1 />
              </Route>
              <Route path="/user/:userId/second-questions/:data_userId">
                <SecondQuestions />
              </Route>
              <Route path="/user/:userId/result">
                <Result />
              </Route>
              <Route path="/report-result/:userId">
                <Report />
              </Route>
              <ProtectedRoute path="/coach" component={Coach} />
              <Redirect to="/" />
            </Switch>
          </div>
          <Footer />
        </div>
      </Router>
    </LanguageProvider>
  );
}

export default App;