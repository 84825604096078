import React, { useEffect } from 'react';
import { useLanguage } from './LanguageContext';

const PaymentFailurePage = () => {
  const { language } = useLanguage();
  const strings = require(`./${language}.json`);
  useEffect(() => {
    document.documentElement.lang = language;
  }, [language]);

  const handleReturnToMainScreen = () => {
    window.location.href = '/account';
  };

  return (
    <div>
      <h1>{strings.PaymentFailed}</h1>
      {/* Возможно, здесь добавить дополнительный контент или ссылку */}
      <button onClick={handleReturnToMainScreen}>{strings.returnToMainScreen}</button>
    </div>
  );
};

export default PaymentFailurePage;