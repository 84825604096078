import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import { useLanguage } from './LanguageContext';
import Cookies from 'js-cookie';
import AuthorisationError from './AuthorisationError';
import NotFoundPage from './NotFoundPage';
import LoadingPage from './LoadingPage';
import styles from './Settings.module.css';
import { toast, ToastContainer } from 'react-toastify';

const Settings = () => {
  const { language } = useLanguage();
  const strings = require(`./${language}.json`);

  const [isAuthorized, setIsAuthorized] = useState(null);
  const [loading, setLoading] = useState(true);
  const [selectedLanguage, setSelectedLanguage] = useState(language);
  const [successMessage, setSuccessMessage] = useState('');
  const [activeSection, setActiveSection] = useState('personal');
  const history = useHistory();
  const [subscriptionIsActive, setSubscriptionIsActive] = useState(null);
  const [subscriptionDate, setSubscriptionDate] = useState(null);
  const [subscriptionNextPayDate, setSubscriptionNextPayDate] = useState(null);
  const token = Cookies.get('authToken');
  const [authorisationError, setAuthorisationError] = useState(false);
  const [notFound, setNotFound] = useState(false);
  const [isSubscriptionCancelled, setIsSubscriptionCancelled] = useState(false);
  const [paymentStatus, setPaymentStatus] = useState(null); // Состояние для отслеживания статуса оплаты
  const [showModal, setShowModal] = useState(false);
  const modalRef = useRef(null);

  const openModal = () => setShowModal(true);
  const closeModal = () => setShowModal(false);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        closeModal();
      }
    };

    if (showModal) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showModal]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('ru-RU', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit'
    });
  };

  const handleBackClick = () => {
    history.push('/account');
  };

  useEffect(() => {
    document.documentElement.lang = language;
  }, [language]);

  useEffect(() => {
    const authToken = Cookies.get('authToken');
    if (!authToken) {
      setIsAuthorized(false);
      setLoading(false);
    } else {
      verifyAuthToken(authToken).then((isValid) => {
        if (isValid) {
          setIsAuthorized(true);
          fetchCurrentLanguage(authToken);
        } else {
          Cookies.remove('authToken');
          setIsAuthorized(false);
          setLoading(false);
        }
      });
    }
  }, []);

  const fetchCurrentLanguage = async (authToken) => {
    try {
      const response = await axios.get(
        window.location.origin === 'http://localhost:3000'
          ? 'http://localhost:8000/api/settings/'
          : `${window.location.origin}/api/settings/`,
        {
          headers: {
            Authorization: `Token ${authToken}`,
            'Content-Type': 'application/json',
          },
        }
      );

      if (response.status === 200) {
        setSelectedLanguage(response.data.language);
      }
    } catch (error) {
      console.error('Error fetching current language:', error);
    } finally {
      setLoading(false);
    }
  };

  const fetchSubscriptionStatus = useCallback(async () => {
    try {
      const response = await axios.get(
        window.location.origin === 'http://localhost:3000'
          ? 'http://localhost:8000/api/subscription_status/'
          : `${window.location.origin}/api/subscription_status/`,
        {
          headers: {
            Authorization: `Token ${token}`
          }
        }
      );
      setSubscriptionIsActive(response.data.subscription_is_active);
      setSubscriptionDate(formatDate(response.data.subscription_end_time));
      setSubscriptionNextPayDate(response.data.subscription_next_pay_date);
    } catch (error) {
      handleRequestError(error);
    }
  }, [token]); // Добавляем зависимости

  useEffect(() => {
    fetchSubscriptionStatus();
  }, [fetchSubscriptionStatus]); // Вызов функции в useEffect

  const handleSubscription = async () => {
    try {
      const response = await axios.post(
        window.location.origin === 'http://localhost:3000'
          ? 'http://localhost:8000/api/create_subscription/'
          : `${window.location.origin}/api/create_subscription/`,
        {},
        {
          headers: {
            Authorization: `Token ${token}`
          }
        }
      );
      window.location.href = response.data.subscription_url;
    } catch (error) {
      handleRequestError(error);
    }
  };

  const handlePaymentSuccess = useCallback(async () => {
    try {
      const params = new URLSearchParams(window.location.search);
      const session_id = params.get('session_id');

      if (!session_id) {
        throw new Error('Session ID is missing in the URL parameters.');
      }

      const response = await axios.get(
        window.location.origin === 'http://localhost:3000'
          ? `http://localhost:8000/api/handle-payment-success/?session_id=${session_id}`
          : `${window.location.origin}/api/handle-payment-success/?session_id=${session_id}`,
        {
          headers: {
            Authorization: `Token ${token}`
          }
        }
      );

      console.log(response.data.message);

      const newUrl = new URL(window.location);
      newUrl.searchParams.delete('success');
      newUrl.searchParams.delete('session_id');
      window.history.replaceState(null, '', newUrl);

    } catch (error) {
      console.error('Error handling payment success:', error);
      // Обработка ошибок при обработке успешной оплаты
    }
  }, [token]);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    if (params.get('success')) {
      setPaymentStatus('success');
    } else if (params.get('canceled')) {
      setPaymentStatus('canceled');
    }
  }, []);

  useEffect(() => {
    if (paymentStatus === 'success') {
      handlePaymentSuccess();
    }
  }, [paymentStatus, handlePaymentSuccess, token]);

  const handleCancelSubscription = async () => {
    try {
      await axios.post(
        window.location.origin === 'http://localhost:3000'
          ? 'http://localhost:8000/api/cancel_subscription/'
          : `${window.location.origin}/api/cancel_subscription/`,
        {},
        {
          headers: {
            Authorization: `Token ${token}`
          }
        }
      );
      await fetchSubscriptionStatus();
      closeModal();
      setIsSubscriptionCancelled(true); // Установить состояние, что подписка отменена
      toast.success(strings.subscriptionCancelled); // Display success message
    } catch (error) {
      console.error('Error canceling subscription:', error);
      handleRequestError(error);
    }
  };

  const handleRequestError = (error) => {
    setLoading(false);
    if (error.response) {
      if (error.response.status === 401 || error.response.status === 403) {
        setAuthorisationError(true);
        Cookies.remove('authToken');
      } else if (error.response.status === 404) {
        setNotFound(true);
      } else {
        // setError('An unexpected error occurred. Please try again later.');
        console.error('API error:', error.response.data.detail);
      }
    } else {
      console.error('Error:', error.message);
    }
  };

  useEffect(() => {
    if (isAuthorized) {
      document.documentElement.lang = language;
    }
  }, [language, isAuthorized]);

  const handleLanguageChange = (event) => {
    setSelectedLanguage(event.target.value);
  };

  const handleLanguageSubmit = async (event) => {
    event.preventDefault();

    const authToken = Cookies.get('authToken');
    if (!authToken) {
      setIsAuthorized(false);
      return;
    }

    try {
      const response = await axios.patch(
        window.location.origin === 'http://localhost:3000'
          ? 'http://localhost:8000/api/settings/'
          : `${window.location.origin}/api/settings/`,
        { language: selectedLanguage },
        {
          headers: {
            Authorization: `Token ${authToken}`,
            'Content-Type': 'application/json',
          },
        }
      );

      if (response.status === 200) {
        setSuccessMessage(strings.languageUpdateSuccess);
      }
    } catch (error) {
      console.error('Error updating language:', error);
    }
  };

  const handleSectionChange = (section) => {
    setActiveSection(section);
  };

  useEffect(() => {
    if (successMessage) {
      const timer = setTimeout(() => {
        setSuccessMessage('');
      }, 5000); // Message will be visible for 5 seconds

      return () => clearTimeout(timer);
    }
  }, [successMessage]);

  if (loading) {
    return <LoadingPage />;
  }

  if (notFound) {
    return <NotFoundPage />;
  }

  if (authorisationError) {
    Cookies.remove('authToken');
    return <AuthorisationError />;
  }

  return (
    <div className={styles.DashboardBrowsingHistory}>
      <ToastContainer />
      <div className={styles.div}>
        <div className={styles.div2}>
          <div className={styles.column}>
            <div className={styles.div3}>
              <div
                className={`${styles.ReturnToPersonalAccount}`}
                onClick={handleBackClick}
              >
                ← {strings.backAccount}
              </div>
              <div className={styles.navigationSideBar}>
                <div
                  className={`${styles.dashboardNavItem} ${activeSection === 'personal' ? styles.activeCategory : ''}`}
                  onClick={() => handleSectionChange('personal')}
                >


                  <svg className={styles.img} xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                    <g clip-path="url(#clip0_4_73)">
                      <path d="M2.5 13.75L10 18.125L17.5 13.75" stroke="#5F6C72" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                      <path d="M2.5 10L10 14.375L17.5 10" stroke="#5F6C72" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                      <path d="M2.5 6.25L10 10.625L17.5 6.25L10 1.875L2.5 6.25Z" stroke="#5F6C72" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    </g>
                    <defs>
                      <clipPath id="clip0_4_73">
                        <rect width="20" height="20" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                  <div className={styles.dashboard}>{strings.personalSettings}</div>
                </div>
                <div
                  className={`${styles.dashboardNavItem2} ${activeSection === 'subscription' ? styles.activeCategory : ''}`}
                  onClick={() => handleSectionChange('subscription')}
                >
                  <svg className={styles.img} xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                    <path d="M16.25 3.125H3.75C3.40482 3.125 3.125 3.40482 3.125 3.75V16.25C3.125 16.5952 3.40482 16.875 3.75 16.875H16.25C16.5952 16.875 16.875 16.5952 16.875 16.25V3.75C16.875 3.40482 16.5952 3.125 16.25 3.125Z" stroke="#5F6C72" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M6.25 3.125V16.875" stroke="#5F6C72" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M8.75 8.75H13.75" stroke="#5F6C72" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M8.75 11.25H13.75" stroke="#5F6C72" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                  <div className={styles.dashboard}>{strings.subscriptionSettings}</div>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.accountSetting}>
            {activeSection === 'personal' ? (
              <div className={styles.setting}>

                <form onSubmit={handleLanguageSubmit}>
                  <div className={styles.div4}>
                    <div className={styles.inputField}>
                      <div className={styles.location}>
                        <div className={styles.country}>
                          <div className={styles.selectLanguageForReport}>
                            {strings.reportLanguage}*
                          </div>
                          <div className={styles.dropdown}>
                            <select
                              className={styles.select}
                              value={selectedLanguage}
                              onChange={handleLanguageChange}
                            >
                              <option value="ru">{strings.languageRu}</option>
                              <option value="en">{strings.languageEn}</option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                    <button className={styles.button} type="submit">
                      {strings.save}
                    </button>
                    {successMessage && <p>{successMessage}</p>}
                  </div>
                </form>

              </div>
            ) : activeSection === 'subscription' ? (
              <div className={styles.subscriptionSection}>
                <h2>{strings.subscription}</h2>
                {subscriptionIsActive && subscriptionDate && subscriptionNextPayDate && (
                  <div>
                    {/* Текст с датой окончания подписки */}
                    <p style={{ textAlign: "left" }}>{`${strings.subscriptionIsActive}`}</p>
                    <p style={{ textAlign: "left" }}>{`${strings.AutoRenewalOfSubscription}`}: {formatDate(subscriptionNextPayDate)}</p>

                    {/* Ссылка отмены подписки будет отображаться только если подписка активна и еще не была отменена */}
                    {!isSubscriptionCancelled && subscriptionNextPayDate !== null && (
                      <div
                        className={`${styles.ReturnToPersonalAccount}`}
                        onClick={openModal}
                        style={{ marginLeft: "0px" }}
                      >
                        {strings.cancelSubscription}
                      </div>
                    )}
                    {showModal && (
                      <div className={styles.modal}>
                        <div className={styles.modalContent} ref={modalRef}>
                          <h3>{strings.confirmCancelSubscription}</h3>
                          <button style={{ marginBottom: "10px", marginTop: "10px" }} onClick={handleCancelSubscription}>{strings.yesCancel}</button>
                          <button onClick={closeModal}>{strings.noKeep}</button>
                        </div>
                      </div>
                    )}
                  </div>
                )}
                {subscriptionNextPayDate === null && subscriptionIsActive && (
                  <div>
                    <p style={{ textAlign: "left" }}>{`${strings.subscriptionIsActiveUntil}: ${subscriptionDate}`}</p>
                    <p style={{ textAlign: "left" }}>{`${strings.autoRenewal}`}</p>
                  </div>
                )}
                {/* Ссылка неактивна или закончилась */}
                {subscriptionIsActive === false && subscriptionDate !== null && (
                  <div>
                    <p style={{ textAlign: "left" }}>
                      {`${strings.subscriptionIsNotActive}`}
                    </p>
                    <div
                      className={`${styles.ReturnToPersonalAccount}`}
                      style={{ marginTop: "30px" }}
                      onClick={handleSubscription}
                    >
                      {strings.subscribe}
                    </div>
                  </div>
                )}
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

const verifyAuthToken = async (token) => {
  return new Promise((resolve) => {
    resolve(true);
  });
};

export default Settings;