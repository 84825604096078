import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import axios from 'axios';
import { useLanguage } from './LanguageContext';

function IntroductionPage({ onStartTest }) {
  const { language } = useLanguage();
  const strings = require(`./${language}.json`);
  useEffect(() => {
    document.documentElement.lang = language;
  }, [language]);
  return (
    <div style={{ marginBottom: '10px' }}>
      <p style={{ color: "#fa5400" }}>{strings.ClosingWarning}</p>
      <h1>{strings.attention2}</h1>
      <p>{strings.agreeDisclaimer}<br />
        {strings.chooseAgree}<br />
        {strings.beHonest2}<br /><br />
        {strings.buttonText2}</p>

        <button onClick={onStartTest}>{strings.continueTest}</button>
    </div>
  );
}

function SecondQuestions() {
  const history = useHistory();
  const [questions, setQuestions] = useState([]);
  const [currentQuestion, setCurrentQuestion] = useState(0);
  useEffect(() => {
    // Прокручиваем до верха страницы при изменении currentQuestion
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [currentQuestion]);
  const [selectedOption, setSelectedOption] = useState("");
  const { userId } = useParams();
  const { data_userId } = useParams();
  const [errorText, setErrorText] = useState('');
  const [showIntroduction, setShowIntroduction] = useState(true);

  const { language } = useLanguage();
  const strings = require(`./${language}.json`);
  useEffect(() => {
    document.documentElement.lang = language;
  }, [language]);

  const handleOptionChange = (option) => {
    setSelectedOption(option);
  };

  useEffect(() => {
    axios.get(`${window.location.origin === 'http://localhost:3000' ? 'http://localhost:8000/api/second-questions/' : `${window.location.origin}/api/second-questions/`}?user_id=${data_userId}`, {
      headers: {
        'Cache-Control': 'no-cache'
      }
    })
      .then(response => setQuestions(response.data))
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 500) {
            console.error('Ошибка 500. Внутренняя ошибка сервера:', error);
            setErrorText(strings.errorServer500);
          }
        }
        if (error.response.status === 400) {
          if (error.response.data && error.response.data.detail === 'User session already exists') {
            console.error('Ошибка: User session already exists');
            setErrorText(strings.errorUserSessionExists);
          }
        }
        else {
          console.error('Ошибка:', error);
          setErrorText(strings.errorUnexpected);
        }
      });
  }, [data_userId, strings]);

  const handleNext = () => {
    const answer = {
      answer: selectedOption,
      user: data_userId
    };

    axios.post(`${window.location.origin === 'http://localhost:3000'
      ? `http://localhost:8000/api/questions/${userId}/${questions[currentQuestion].id}/answers/`
      : `${window.location.origin}/api/questions/${userId}/${questions[currentQuestion].id}/answers/`
      }`, answer, {
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then(response => {
        console.log(response.data);

        if (currentQuestion === questions.length - 1) {
          const submitTest = {
            user_id: data_userId,
            link: userId,
          };

          axios.post(`${window.location.origin === 'http://localhost:3000' ? 'http://localhost:8000/api/submit-test/' : `${window.location.origin}/api/submit-test/`}`, submitTest, {
            headers: {
              'Content-Type': 'application/json'
            }
          })
            .then(response => {
              console.log(response.data);
              history.push(`/coach`);
            })
            .catch(error => {
              console.error('Error submitting test:', error);
              setErrorText(strings.errorUnexpected);
            });
        } else {
          setCurrentQuestion(currentQuestion + 1);
          setSelectedOption("");
        }
      })
      .catch((error) => {
        if (error.response.status === 500) {
          console.error('Ошибка 500. Внутренняя ошибка сервера:', error);
          setErrorText(strings.errorServer500);
        }
        else if (error.response.status === 404) {
          console.error('Ошибка 404. Страница не найдена', error);
          setErrorText(strings.errorQuestionNotFound);
        }
        else if (error.response.status === 400) {
          if (error.response.status === 400 && error.response.data.detail === "User has already answered this question.") {
            setErrorText(strings.errorAlreadyAnswered);
          }
          else if (error.response.data && error.response.data.detail === 'Вы не можете отвечать на один вопрос несколько раз') {
            console.error('Ошибка: Вы не можете отвечать на один вопрос несколько раз');
            setErrorText(strings.errorCannotAnswerMultipleTimes);
          }
          else if (error.response.data && error.response.data.detail === 'User not registered with this link') {
            console.error('Ошибка: User not registered with this link');
            setErrorText(strings.errorUserNotRegistered);
          }
        }
        else {
          console.error('Ошибка:', error);
          setErrorText(strings.errorUnexpected);
        }
      });
  };

  const handleStartTest = () => {
    setShowIntroduction(false);
  };

  return (
    <div className="question-container">
      {showIntroduction ? (
        <IntroductionPage onStartTest={handleStartTest} />
      ) : (
        <>
          <p style={{ color: "#fa5400" }}>{strings.ClosingWarning}</p>
          <h1>{questions[currentQuestion]?.question_numbering}</h1>
          <h4>{questions[currentQuestion]?.question_text}</h4>
          <div>
            <input type="radio" id="option1" name="option" value="Option 1" onChange={() => handleOptionChange("1")}
              checked={selectedOption === "1"} />
            <label htmlFor="option1">{strings.agree}</label><br />
            <input type="radio" id="option2" name="option" value="Option 2" onChange={() => handleOptionChange("2")}
              checked={selectedOption === "2"} />
            <label htmlFor="option2">{strings.disagree}</label><br />
          </div>
          <button onClick={handleNext}>{strings.nextButton}</button>
          {errorText && <p style={{ color: 'red' }}>{errorText}</p>}
        </>
      )}
    </div>
  );
}

export default SecondQuestions;