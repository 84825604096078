import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import Cookies from 'js-cookie';

const ProtectedRoute = ({ component: Component, ...rest }) => {
  const isAuthenticated = !!Cookies.get('authToken'); // Проверяем наличие токена

  return (
    <Route
      {...rest}
      render={(props) => {
        if (isAuthenticated) {
          return <Component {...props} />;
        } else {
          // Сохраняем текущий путь в cookies
          Cookies.set('redirectAfterLogin', props.location.pathname, { expires: 1 }); // Сохраняем на 1 день
          return <Redirect to="/" />; // Перенаправляем на страницу входа
        }
      }}
    />
  );
};

export default ProtectedRoute;