import React, { useEffect } from 'react';
import { useLanguage } from '../LanguageContext';

const Footer = () => {
    const { language } = useLanguage();
    const strings = require(`../${language}.json`);

    useEffect(() => {
        document.documentElement.lang = language;
    }, [language]);

    return (
        <footer className="footer">
            <p>{strings.connect} - <a href="mailto:dimakiriek@gmail.com">dimakiriek@gmail.com</a></p>
        </footer>
    );
};

export default Footer;