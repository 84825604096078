import React, { useEffect } from 'react';
import { useLanguage } from './LanguageContext';

const SomethingWrong = () => {
    const { language } = useLanguage();
  const strings = require(`./${language}.json`);
  useEffect(() => {
    document.documentElement.lang = language;
  }, [language]);
  return (
    <div>
      <h1>{strings.oops}</h1>
      <p>{strings.SomethingWrong}</p>
    </div>
  );
}

export default SomethingWrong;